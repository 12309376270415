<template>
  <div class="home">
    <!-- bg -->
    <div class="home-bg" v-if="isDaPerson == 0 || isDaPerson == 1 || isDaPerson == 2"></div>
    <!-- 主要内容 -->
    <div class="home-main" v-if="isDaPerson == 0 || isDaPerson == 1 || isDaPerson == 2">
      <!-- <div class="flex flex-between align-center home-main-search">
        <div class="flex flex-left align-center home-main-search-l" @click="gotoPagesFunc('search')">
          <img class="home-main-search-l-icon" src="../../assets/img/home/search.png" alt="">
          <input class="home-main-search-l-input" type="text" placeholder="请输入要搜索的内容" readonly>
        </div>
        <div class="flex flex-right align-center home-main-search-r" @click="gotoPagesFunc('xs')">
          <img class="home-main-search-r-icon" src="../../assets/img/home/sx.png" alt="">
          筛选
        </div>
      </div> -->
      <!-- banner -->
      <!-- <div class="home-main-banner">
        <van-swipe :autoplay="3000" class="home-main-banner-swiper">
          <van-swipe-item v-for="image in bannerList" :key="image">
            <img :src="image.photoUrl" class="home-main-banner-swiper-img"/>
          </van-swipe-item>
        </van-swipe>
      </div> -->
      <!-- 20220119 升级成为种草达人任务专区 -->
      <div class="home-main-newgz">
        <div class="flex flex-left align-center home-main-newgz-bg" :style="{background: 'url('+require('../../assets/img/home/newdr-bg.png')+')no-repeat top left', backgroundSize: 'cover'}">
          《流媒体营销密码与私域带货实战课程套装》介绍
        </div>
        <div class="home-main-newgz-cont">
          ✅一条视频，让一个宠物淘宝店铺月营业额💰。<br/>
          ✅一条视频，让一款普通牛肉酱店铺日人流量7万+，日成交额。<br/>
          ✅一条视频，让一个个景区🏝，一座座城市🏢焕发了新的生命力。<br/>
          <p>
            🎬流媒体让素人成为红人！让老店焕发生机！在这一切的背后隐藏着怎样的💬秘密?
          </p>
          <p>📣当营销手段从图文🏞走向视频🎞；营销范围从公域走向私域；营销策略从单一走向矩阵；营销表达从品牌走向内容。外部环境的变化给各行各业带来了新的挑战和机会。</p>
          <p>📣以抖音📱为主要介质的短视频营销正在兴起📈。爆款短视频该如何打造?如何构建系统化的短视频内容体系？🔍最近爆火的私域带货的流程与套路有哪些?</p>
          <p>💡答案尽在 <br/>
          <span  style="color: #E62129;">《流媒体营销密码与私域带货实战课程套装》</span>📒之中</p>
        </div>
        <div class="home-main-newgz-toast">
          * 本服务只限于线下会员，暂不支持线上
        </div>
      </div>
      <!-- 商品信息 -->
      <div class="home-main-goodsinfo">
        <img class="home-main-goodsinfo-img" src="../../assets/img/home/dapreson-goods.png" alt="">
        <div class="home-main-goodsinfo-con">
          <div class="flex flex-left align-center home-main-goodsinfo-con-price">
            ¥618
            <!-- <span>¥5000</span> -->
          </div>
          <div class="home-main-goodsinfo-con-pro">
            <div class="home-main-goodsinfo-con-pro-title">课程收益：</div>
            <div class="home-main-goodsinfo-con-pro-info">
              ✅&nbsp;掌握抖音帐号定位规划的原则与主页装修技巧掌握抖音帐号分阶段运营要点；<br/>
              ✅&nbsp;账号冷启动；从素人到达人的转变；<br/>
              ✅&nbsp;掌握抖音短视频流量变现的方法；<br/>
              ✅&nbsp;掌握私域带货的流程、套路、技巧；<br/>
              ✅&nbsp;DNS任务系统会员身份认证，提升学员流量变现可能；<br/>
              ✅&nbsp;课程完成后，随机抽取高价值红酒盲盒；
            </div>
          </div>
          <div class="flex flex-center align-center home-main-goodsinfo-con-btn" @click="buyFunc">立即购买，升级为达人</div>
        </div>
      </div>
    </div>

    <!-- 个人达人升级成为门店达人 v-if="userTypeStatus.memberType == 1"-->
    <newApplyMembers v-if="isDaPerson == 3 || isDaPerson == 4 || isDaPerson == 5"></newApplyMembers>

    <!-- 异常提醒 -->
    <errorToast :errortoast="errortoast"></errorToast>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
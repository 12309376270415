<template>
  <div class="apply-member">
    <!-- <img v-if="status == 0" src="@/assets/img/applyMembers/four.png" class="four" /> -->
    <div class="apply-holder">
      <div :class="['a-h flex', {'a-three': status == 2 }]">
        <!-- <img src="@/assets/img/store/picture.png" class="pic"> -->
        <img :src="userInfo.avator" class="pic" />
        <div class="h-center">
          <p class="flex flex-left align-center">{{wxInfo.weixin_name}}
            <img v-if="userInfo.memberType == 3" src="@/assets/img/user/home/crown1.png" alt="" />
            <img v-if="userInfo.memberType == 4" src="@/assets/img/user/home/crown2.png" alt="" />
            <img v-if="userInfo.memberType == 2" src="@/assets/img/user/home/crown3.png" alt="" />
          </p>
<!--          <p>邀请码：{{userInfo.invitationCode}}</p>-->
        </div>
        <div v-if="isAuth" class="h-right flex align-center"  @click="goShopAuthFunc">
          <!-- <p>升级成为门店达人</p> -->
          <p>查看兑换内容</p>
          <img src="@/assets/img/applyMembers/right.png" class="right">
        </div>
        <!-- <div v-if="status == 0" class="flex align-center h-second">
          <img src="@/assets/img/store/clock.png" class="clock">
          <p>门店认证审核中</p>
        </div>
        <div v-if="status == 2" class="flex align-center h-third">
          <img src="@/assets/img/store/close.png" class="clock">
          <p class="fail">门店认证失败</p>
          <div class="b-b">
            <p class="reason">失败原因：{{refuseReason}}</p>
            <p class="edit" @click="goShopAuthFunc">修改认证信息再提交</p>
          </div>
        </div> -->
      </div>
      <div style="display:none;" :class="['a-ul', {'a-ul-three': status == 3 }]" @click="goHomeFunc">
        <div class="a-li flex flex-between">
          <img src="@/assets/img/applyMembers/pack.png" class="left">
          <div class="line flex align-center">
            <p>给身边人种草优惠好物</p>
            <img src="@/assets/img/applyMembers/right-second.png" class="right">
          </div>
        </div>
        <div class="a-li flex flex-between">
          <img src="@/assets/img/applyMembers/mon.png" class="left">
          <div class="line flex align-center">
            <p>推荐灵龙会员成为种草达人</p>
            <img src="@/assets/img/applyMembers/right-second.png" class="right">
          </div>
        </div>
        <div class="a-li flex flex-between li-third">
          <img src="@/assets/img/applyMembers/mon.png" class="left">
          <div class="line flex align-center">
            <p>优惠自购所需吃的、喝的、用的、<br>得更多实用龙珠</p>
            <img src="@/assets/img/applyMembers/right-second.png" class="right">
          </div>
        </div>
      </div>
      <div class="box-two flex flex-between">
        <p @click="goPageFunc(0)">我的粉丝</p>
        <p @click="goPageFunc(1)">查看我的收益</p>
      </div>
    </div>
    <div :class="['tilte flex align-center flex-center', {'status-two': status == 1 || status == 2 }]">
      <img src="@/assets/img/applyMembers/14.png" class="left" />
      <p>达人课程介绍</p>
      <img src="@/assets/img/applyMembers/15.png" class="right" />
    </div>
    <!-- 商品信息 -->
    <div class="apply-member-two">
        <div class="apply-member-goodsinfo">
            <img class="apply-member-goodsinfo-img" src="@/assets/img/home/dapreson-goods.png" alt="">
            <div class="apply-member-goodsinfo-con">
                <div class="flex flex-left align-center apply-member-goodsinfo-con-price">
                    ¥618
                    <!-- <span>¥5000</span> -->
                </div>
                <div class="apply-member-goodsinfo-con-pro">
                    <div class="apply-member-goodsinfo-con-pro-title">课程收益：</div>
                    <div class="apply-member-goodsinfo-con-pro-info">
                        ✅&nbsp;掌握抖音帐号定位规划的原则与主页装修技巧掌握抖音帐号分阶段运营要点；<br/>
                        ✅&nbsp;账号冷启动；从素人到达人的转变；<br/>
                        ✅&nbsp;掌握抖音短视频流量变现的方法；<br/>
                        ✅&nbsp;掌握私域带货的流程、套路、技巧；<br/>
                        ✅&nbsp;DNS任务系统会员身份认证，提升学员流量变现可能；<br/>
                        ✅&nbsp;课程完成后，随机抽取高价值红酒盲盒；
                    </div>
                </div>
                <div class="flex flex-center align-center apply-member-goodsinfo-con-btn" @click="goShareFunc">一键转发，让更多人一起学习</div>
            </div>
        </div>

        <div class="apply-member-newgz">
            <div class="flex flex-left align-center apply-member-newgz-bg" :style="{background: 'url('+require('@/assets/img/home/newdr-bg.png')+')no-repeat top left', backgroundSize: 'cover'}">
            《流媒体营销密码与私域带货实战课程套装》介绍
            </div>
            <div class="apply-member-newgz-cont">
                ✅一条视频，让一个宠物淘宝店铺月营业额💰。<br/>
                ✅一条视频，让一款普通牛肉酱店铺日人流量7万+，日成交额。<br/>
                ✅一条视频，让一个个景区🏝，一座座城市🏢焕发了新的生命力。<br/>
                <p>
                    🎬流媒体让素人成为红人！让老店焕发生机！在这一切的背后隐藏着怎样的💬秘密?
                </p>
                <p>📣当营销手段从图文🏞走向视频🎞；营销范围从公域走向私域；营销策略从单一走向矩阵；营销表达从品牌走向内容。外部环境的变化给各行各业带来了新的挑战和机会。</p>
                <p>📣以抖音📱为主要介质的短视频营销正在兴起📈。爆款短视频该如何打造?如何构建系统化的短视频内容体系？🔍最近爆火的私域带货的流程与套路有哪些?</p>
                <p>💡答案尽在 <br/>
                <span  style="color: #E62129;">《流媒体营销密码与私域带货实战课程套装》</span>📒之中</p>
            </div>
            <div class="apply-member-newgz-toast">
                * 本服务只限于线下会员，暂不支持线上
            </div>
        </div>

        <!-- 二维码 -->
    <qrToast :qrtoast="qrtoast"></qrToast>
    </div>
    <div class="apply-member-bot"></div>
  </div>
</template>
<script>
import {
    getweixininfo
} from '@/utils/user.js';
import { provide, ref } from 'vue';
import qrToast from '@/components/qr.vue'
export default {
    name: "ApplyMembers",
    components: {qrToast},
    setup() {
        let qrtoast = ref(false);
        provide('qrtoast', qrtoast);
        return {
            qrtoast
        }
    },
    data() {
        return {
            isAuth: true,
            status: -1,
            goodsList: [],
            goodsList1: [],
            userInfo: '',
            refuseReason: '',
            wxInfo: '',
        }
    },
    created() {
        this.wxInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        console.log('1212', this.wxInfo)
        this.initData();
    },
    methods: {
      // 获取用户邀请码
      initData() {
            let data = {
                memberId: this.$cookies.get('memberId')
            };
            getweixininfo(data).then(res => {
                if (res && res.code == "0") {
                    this.userInfo = res.data || {}
                }
            })
        },
      goShopAuthFunc() {
        // this.$router.push({
        //   path: '/storeAuth'
        // })
        this.qrtoast = true;
      },
      // 查看我的粉丝 我的收益
      goPageFunc(idx) {
        let url = '';
        switch(idx) {
          case 0:
            url= '/myFans';
            break;
          case 1:
            url= '/profitRecord';
            break;
        }
        this.$router.push({
          path: url
        })
      },
      // 跳转到首页
      goHomeFunc() {
        this.$router.push({
          path: '/'
        })
      },
        //   跳转到分享
        goShareFunc() {
            this.$router.push({
                path: '/shareDaPerson'
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.apply-member{
    width: 100%;
    height: auto;
    background: #3D3D3D;
    padding-bottom: 20px;
    .four{
        width: 100%;
    }
    .apply-holder{
        padding-top: 15px;
        .a-h{
            width: 345px;
            margin: 0 auto;
            .pic{
                width: 52px;
                height: 52px;
                flex-shrink: 0;
                border-radius: 50%;
                overflow: hidden;
            }
            .h-center{
              padding-top: 5px;
              margin-left: 10px;
              flex-grow: 1;
              p:nth-child(1) {
                height: 21px;
                font-size: 15px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 21px;
                margin-bottom: 5px;
                img{
                    width: 64px;
                    height: 20px;
                    object-fit: scale-down;
                    overflow: hidden;
                    margin-left: 5px;
                }
              }
              p:nth-child(2) {
                height: 16px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.8);
                line-height: 16px;
              }
            }
            .h-right{
                width: auto;
                height: 25px;
                background: linear-gradient(270deg, #F4D191 0%, #FDEABE 58%, #F9E9BE 100%);
                border-radius: 12px;
                margin-top: 12px;
                p{
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #AB6E14;
                    margin-left: 10px;
                }
                img.right{
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                }
            }
            .h-second{
                width: 121px;
                height: 26px;
                background: #FCDDB1;
                border-radius: 14px;
                margin-top: 13px;
                .clock{
                    width: 18px;
                    height: 18px;
                    flex-shrink: 0;
                    margin:0 4px 0 9px;
                }
                p{
                    font-size: 11px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #CD8712;
                }
            }
            .h-third{
                width: 110px;
                height: 26px;
                background: #ECECEC;
                border-radius: 14px;;
                margin-top: 5px;
                position: relative;
                .clock{
                    width: 18px;
                    height: 18px;
                    flex-shrink: 0;
                    margin:0 4px 0 9px;
                }
                p.fail{
                    font-size: 11px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #999999;
                }
                .b-b{
                    width: 170px;
                    height: 69px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 10px;
                    padding-top: 10px;
                    position: absolute;
                    right: 0;
                    top: 31px;
                    .reason{
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.7);
                        text-align: center;
                    }
                    .edit{
                        width: 140px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        border-radius: 16px;
                        border: 1px solid rgba(255, 255, 255, 0.49);
                        margin: 10px auto 0;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.7);
                    }
                }
            }
        }
        .a-three{
            height: 115px;
        }
        .a-ul{
            width: 316px;
            height: 190px;
            background: rgba(255, 255, 255, 0.15);
            border-radius: 6px;
            margin: 20px auto 25px;
            padding: 14px 15px 0 14px;
            .a-li{
                height: 46px;
                margin-bottom: 9px;
                padding-bottom: 9px;
                img.left{
                    width: 46px;
                    height: 46px;
                    flex-shrink: 0;
                }
                .line{
                    width: 237px;
                    height: 46px;
                    border-bottom: solid 1px #3D3D3D;
                    padding-bottom: 8px;
                }
                p{
                    flex-grow: 1;
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FCEABE;
                    line-height: 18px;
                    text-align: right;
                }
                img.right{
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                    margin-left: 6px;
                }
            }
            .li-third{
                margin-bottom: 0;
                padding-bottom: 0;
                .line{
                    border: none;
                }
            }
        }
        .a-ul-three{
            margin-top: 15px;
        }
        .box-two{
            width: 345px;
            margin: 0 auto;
            margin-top: 20px;
            p{
                width: 165px;
                height: 44px;
                border-radius: 22px;
                opacity: 0.7;
                border: 1px solid rgba(255, 255, 255, 0.7);
                line-height: 44px;
                text-align: center;
                font-size: 15px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.7);
            }
        }
    }
    .tilte{
        height: 22px;
        margin-top: 20px;
        img{
            width: 57px;
            height: 8px;
        }
        p{
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFDCA2;
            margin: 0 23px 0 24px;
        }
    }
    .status-two{
        margin-top: 30px !important;
    }
    .a-box{
        width: 345px;
        margin: 21px auto 0;
        .t-hold{
            height: 165px;
            background: rgba(255, 255, 255, 0.95);
            border-radius: 10px;
            padding: 15px 0 0;
            position: relative;
            overflow: hidden;
            .t-top{
                height: 16px;
                img{
                    flex-shrink: 0;
                    width: 16px;
                    height: 14px;
                }
                p{
                    margin: 0 8px 0 10px;
                    height: 16px;
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #AC6A1A;
                    line-height: 16px;
                }
            }
            .t-ul{
                padding: 24px 34px 0 33px;
                .t-li{
                    width: 48px;
                    img{
                        width: 48px;
                        height: 48px;
                    }
                    p{
                        display: flex;
                        justify-content: center;
                        width: 48px;
                        white-space:nowrap;
                        height: 14px;
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 14px;
                        margin-top: 5px;
                    }
                }
            }
            .s-b-b{
                width: 100%;
                height: 44px;
                background: linear-gradient(135deg, #545454 0%, #A1A1A1 68%, #585858 100%);
                -webkit-border-radius: 0px 0px 10px 10px;
                border-radius: 0px 0px 10px 10px;
                text-align: center;
                line-height: 44px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                position: absolute;
                bottom: 0;
                left: 0;
                i{
                    font-style: normal;
                    font-weight: 600;
                }
                span{
                  color: #FFE3C5;
                  font-weight: bold;
                }
            }
        }
        .s-hold{
            height: 123px;
            padding: 17px 20px 0 0;
            background: #ffffff;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            opacity: 0.95;
            margin-top: 9px;
            .s-h-l{
              flex-grow: 1;
              .one{
                height: 16px;
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #3D3D3D;
                margin-left: 15px;
                text-align: left;
                img{
                  width: 16px;
                  height: 10px;
                  margin: 0 6px;
                }
              }
              .center{
                  width: 183px;
                  height: 17px;
                  margin: 9px 0 18px 16px;
              }
              .bottom{
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 14px;
                margin-left: 14px;
              }
            }
            .s-h-r{
                position: relative;
                flex-shrink: 0;
                width: 80px;
                height: 106px;
                &-img {
                    width: 80px;
                    height: 106px;
                    vertical-align: top;
                }
                &-price {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 45px;
                    color: #FB1B24;
                    font-weight: bold;
                    text-align: center;
                    font-size: 30px;
                    font-family: Antonio-Bold, Antonio;
                    span {
                        font-size: 10px;
                        font-weight: 600;
                    }
                }
            }
        }
        .a-title{
            height: 24px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #B9A991;
            line-height: 24px;
            text-align: left;
            position: relative;
            text-indent: 14px;
        }
        .a-title::before{
            content: "";
            width: 3px;
            height: 14px;
            background: #B4996F;
            -webkit-border-radius: 4px;
            border-radius: 4px;
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        .hold{
            height: 170px;
            background: url("../assets/img/applyMembers/13.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 9px;
            padding: 10px 6px 0 15px;
            .t-h{
                height: 21px;
               p{
                height: 21px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #E62129;
                line-height: 21px;
               }
               img{
                 width: 58px;
                 height: 15px;
                 margin: 4px 0 0 6px;
               }
            }
            .s-h{
                margin-top: 12px;
                text-align: left;
                .s-li{
                    width: 81px;
                    &-img{
                        width: 72px;
                        height: 72px;
                        border-radius: 5px;
                        overflow: hidden;
                        margin-bottom: 8px;
                    }
                    .t-t,.t-d{
                        white-space:nowrap;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        height: 14px;
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: 14px;
                    }
                    .t-t{
                        color: #333333;
                    }
                    .t-d{
                        color: #999999;
                    }
                    .b-b{
                        height: 14px;
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 14px;
                        white-space:nowrap;
                        margin-top: 8px;
                        span{
                            color: #E62129;
                            font-size: 12px;
                            i{
                                font-style: normal;
                            }
                        }
                        .l-s{
                            font-family: Antonio-Bold, Antonio;
                            font-weight: bold;
                        }
                        .r-s{
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        .hold1 {
            width: 100%;
            height: auto;
            margin-top: 10px;
            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }
        }
    }
    .second-box{
        .hold{
            background: url("../assets/img/applyMembers/12.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    .four-box{
        padding-bottom: 13px;
    }
    &-two{
        width: auto;
        height: auto;
        padding: 0px 16px;
    }
    &-goodsinfo{
        width: 100%;
        height: auto;
        margin-top: 15px;
        border-radius: 10px;
        overflow: hidden;
        &-img{
            width: 100%;
            height: 612px;
            object-fit: cover;
            overflow: hidden;
        }
        &-con{
            width: auto;
            height: auto;
            padding: 12px 15px;
            background-color: #fff;
            &-price{
                width: 100%;
                height: auto;
                color: #E62129;
                font-size: 20px;
                font-weight: bold;
                font-family: Antonio-Bold, Antonio;
                span{
                    color: #999999;
                    font-size: 14px;
                    font-weight: 400;
                    text-decoration: line-through;
                    margin-left: 10px;
                }
            }
            &-pro{
                width: auto;
                height: auto;
                padding: 15px;
                background-color: #F6F6F6;
                border-radius: 10px;
                overflow: hidden;
                margin-top: 12px;
                &-title{
                    width: 100%;
                    height: auto;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                }
                &-info{
                    width: auto;
                    height: auto;
                    color: #333333;
                    font-size: 13px;
                }
            }
            &-btn{
                width: 315px;
                height: 44px;
                color: #fff;
                font-size: 15px;
                font-weight: 500;
                margin-top: 20px;
                border-radius: 44px;
                background-color: #E62129;
            }
        }
    }
    &-newgz {
        position: relative;
        width: auto;
        height: auto;
        margin-top: 20px;
        padding: 15px;
        background-color: #fff;
        border: 4px solid #FFCC8B;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        &-bg {
            position: absolute;
            top: -14px;
            left: -4px;
            width: 332px;
            height: 32px;
            color: #fff;
            font-size: 13px;
            font-weight: 500;
            text-align: left;
            text-indent: 8px;
        }
        &-cont {
            width: auto;
            height: auto;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            margin-top: 10px;
            p{
                margin-top: 5px;
            }
        }
        &-toast {
            width: auto;
            height: auto;
            color: #E47E11;
            font-size: 12px;
            font-weight: 400;
            margin-top: 8px;
            &-btn {
                display: inline-block;
                width: 60px;
                height: 20px;
                color: #4096FC;
                font-size: 11px;
                font-weight: 400;
                text-align: center;
                line-height: 21px;
                border: 1px solid #4096FC;
                border-radius: 2px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                -ms-border-radius: 2px;
                -o-border-radius: 2px;
            }
        }
    }
    &-bot{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 70px;
        background: #3D3D3D;
    }
}
</style>

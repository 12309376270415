<template>
    <div>
      <van-popup
        v-model:show="showErrorToast"
        position="center"
        :style="{ width: '100%' }"
        :close-on-click-overlay="false"
        class="user-phone-popup"
      >
        <div class="info-box">
          <div class="close-img" @click="showErrorToast = false">
            <img src="@/assets/img/user/home/close.png" />
          </div>
          <div class="code flex align-center flex-center">
            <p></p>
            异常提醒
            <p></p>
          </div>
          <div class="center flex align-center">
            <p>
                本服务只限于线下会员，<br/>
                暂不支持线上会员购买。<br/>
                如想继续购买此课程服务，请加下方微信:
            </p>
          </div>
          <div class="pic">
            <div class="">小龙：<span>Luckysally1818</span></div>
          </div>
          <div class="flex flex-center align-center btn" @click="copyFun">复制微信，去添加</div>
        </div>
      </van-popup>
    </div>
</template>
<script>
import { inject } from 'vue'
import useClipboard from "vue-clipboard3";
export default {
    setup() {
      let showErrorToast = inject('errortoast')
      return {
        showErrorToast
      }
    },
    data() {
      return {}
    },
    created() {},
    methods: {
      async copyFun() {
          try {
              const { toClipboard } = useClipboard()
              await toClipboard('Luckysally1818')
              this.$toast.success("已复制微信号")
          } catch (e) {
              console.error(e)
          }
      }
    }
  }
  </script>
  <style lang="scss">
  .user-phone-popup {
      width: 280px !important;
      top: 155px !important;
      transform: translateX(-50%) !important;
      background: none !important;
      overflow-y: unset !important;
      .info-box {
        background: #ffffff;
        -webkit-border-radius: 8px;
        border-radius: 8px;
        position: relative;
        padding: 20px 0 10px;
        margin: 0 auto;
        position: relative;
        .close-img {
          width: 24px;
          height: 24px;
          position: absolute;
          top: -34px;
          right: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .code {
          height: 21px;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
          text-align: center;
          margin-bottom: 15px;
          p {
            background: #e62129;
            width: 8px;
            height: 1.5px;
          }
          p:nth-child(1) {
            margin-right: 5px;
          }
          p:nth-child(2) {
            margin-left: 5px;
          }
        }
        .center {
          padding: 10px 12px;
          p{
            width: 100%;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 1.5;
            text-align: center;
          }
        }
        .pic {
          width: 100%;
          height: auto;
          div {
            width: 195px;
            height: 40px;
            color: #666666;
            font-size: 15px;
            text-indent: 15px;
            line-height: 40px;
            margin: 0 auto;
            border-radius: 5px;
            background-color: #F6F6F6;
            span{
                color: #333333;
            }
          }
        }
        .btn{
            width: 230px;
            height: 36px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            border-radius: 36px;
            margin: 0 auto;
            margin-top: 20px;
            background-color: #E62129;
        }
      }
    }
  </style>
import toastBottom from '@/components/bottom.vue'
import someAudit from '@/components/someAudit.vue'
import applyMembers from '@/components/applyMembers.vue'
import newApplyMembers from '@/components/newApplyMembers.vue'
import { provide, ref } from 'vue';
import {
    getUserStatus,
} from '@/utils/home.js';
import useClipboard from "vue-clipboard3"
import errorToast from '@/components/errorToast.vue'
export default {
    components: { toastBottom, someAudit, applyMembers, errorToast, newApplyMembers },
    setup() {
        let errortoast = ref(false); // 商品规格选择弹窗
        let qrtoast = ref(false);
        provide('errortoast', errortoast);
        provide('qrtoast', qrtoast);
        return {
            errortoast,
            qrtoast
        }
    },
    data() {
        return {
            bannerList: [],
            categaryList: [],
            noticeList: [],
            isShowGg: false,
            page: 1,
            oldPage: 0,
            goodsList: [],
            loading: false,
            finished: false,
            noticeDetail: '',
            isDaPerson: '',
            channel: 3,
            userTypeStatus: '', // isBuy=0(表示未购买种草商品),1=已购买种草商品；memberType就是会员类型,0=普通会员,1=个人达人,2=门店达人
            storeStatus: '', // status=状态(0=待审核，1=审核通过，2=审核拒绝),
            shareMemberId: ''
        }
    },
    created() {
        this.getUserStatusFunc();
        this.shareMemberId = this.$route.query.shareMemberId ? this.$route.query.shareMemberId : '';
    },
    methods: {
        // 获取用户的身份状态
        getUserStatusFunc() {
            let data = {
                memberId: this.$cookies.get('memberId') || this.$route.query.memberId
            }
            getUserStatus(data).then(res => {
                console.log('用户身份状态', res)
                if (res.data) {
                    this.isDaPerson = res.data.memberType || this.$cookies.get('userType');
                    // this.userTypeStatus = res.data;
                    // if ((this.userTypeStatus.isBuy == 0 && this.userTypeStatus.memberType == 0) || this.userTypeStatus.memberType == 2) {
                    //     if (this.isDaPerson == '2') {
                    //         this.channel = 2;
                    //     } else {
                    //         this.channel = 3;
                    //     }
                    // }
                }
            })
        },
        // 复制文案
        async copyFunc() {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard('13661957277')
                this.$toast.success("已复制联系方式")
            } catch (e) {
                console.error(e)
            }
        },
        // 立即购买
        buyFunc() {
            if (this.isDaPerson == 0 || this.isDaPerson == 1) {
                this.errortoast = true;
            } else if (this.isDaPerson == 2) {
                this.$router.push({
                    path: '/membersOrderTrue',
                    query: {
                        shareMemberId: this.shareMemberId,
                        v: new Date().getTime()
                    }
                })
            }
        },
    },
}